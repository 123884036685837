export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/auth/sign-in'
export const SIGNUP_PAGE = '/auth/sign-up'
export const PRODUCTS_PAGE = '/products'


export const LOGIN_ENDPOINT = '/login'
export const ADMIN_LOGIN_ENDPOINT = '/adminlogin'
export const ADMIN_HOME = '/admin-home'
export const ADMIN_AUTH = '/getadminauth'
export const ADMIN_ORDERS = '/admingetorders'
export const ADMIN_CONFIRM_ORDER = '/adminconfirmorder'
export const ADMIN_SHIP_ORDER = '/adminshiporder'
export const ADMIN_DELIVER_ORDER = '/admindeliverorder'

export const SIGNUP_ENDPOINT = '/signup'
export const GOOGLE_SIGNIN = '/auth/google'
export const GET_AUTH_REQ = '/getauth'
export const LOGOUT_REQ = '/logout'
export const GET_USERS_ENDPOINT = '/users'
export const ADD_CATEGORY_ENDPOINT = '/addcategory'
export const GET_CATEGORY_ENDPOINT = '/categories'
export const ADD_PRODUCT_ENDPOINT = '/addproduct'
export const GET_PRODUCT_ENDPOINT = '/products'
export const ADD_BEST_SELLER_ENDPOINT = '/addbestseller'
export const ADD_NEW_SELLER_ENDPOINT = '/addnewseller'
export const GET_CART = '/getcart'
export const UPDATE_CART = '/updatecart'
export const DELETE_FROM_CART = '/deletefromcart'
export const GET_DEFAULT_ADDRESS = '/defaultaddress'
export const PLACE_ORDER = '/placeorder'
export const GET_ORDERS = 'getorders'
export const PAY = '/pay'
export const CAPTURE = '/capture'
export const ADD_REVIEW = '/addreview'
export const GET_AUTH_ENDPOINT = '/getauth'
export const GET_ORDER = '/order/'
export const LANDING_PAGE = '/landingpage'